<script lang="ts" setup>
import { breakpointsConfig } from 'utils/css/breakpoints'

const props = defineProps<{
  from?: keyof typeof breakpointsConfig
  until?: keyof typeof breakpointsConfig
}>()

const mounted = useMounted()
const showFrom = props.from ? useMediaQuerySSR(`(min-width: ${breakpointsConfig[props.from]}px)`, true) : ref<boolean>(false)
const showUntil = props.until ? useMediaQuerySSR(`(max-width: ${breakpointsConfig[props.until] - 1}px)`, true) : ref<boolean>(false)
const show = computed(() => !mounted.value || showFrom.value || showUntil.value)
</script>

<template>
  <div
    v-if="show"
    class="breakpoint"
    :class="[
      props.from ? `breakpoint-from-${from}` : undefined,
      props.until ? `breakpoint-until-${until}` : undefined,
    ]"
  >
    <slot />
  </div>
</template>

<style lang="scss" scoped>
@import 'assets/scss/rules/breakpoints';

.breakpoint {
  display: none;
}

// breakpoint from
.breakpoint-from-phoneBigger {
  @media screen and (min-width: $phone-bigger) {
    display: block;
  }
}
.breakpoint-from-phone {
  @media screen and (min-width: $phone) {
    display: block;
  }
}
.breakpoint-from-tablet {
  @media screen and (min-width: $tablet) {
    display: block;
  }
}
.breakpoint-from-laptop {
  @media screen and (min-width: $laptop) {
    display: block;
  }
}
.breakpoint-from-desktop {
  @media screen and (min-width: $desktop) {
    display: block;
  }
}
.breakpoint-from-desktopLarge {
  @media screen and (min-width: $desktop-large) {
    display: block;
  }
}

// breakpoint until
.breakpoint-until-phoneBigger {
  @media screen and (max-width: ($phone-bigger - 1)) {
    display: block;
  }
}
.breakpoint-until-phone {
  @media screen and (max-width: ($phone - 1)) {
    display: block;
  }
}
.breakpoint-until-tablet {
  @media screen and (max-width: ($tablet - 1)) {
    display: block;
  }
}
.breakpoint-until-laptop {
  @media screen and (max-width: ($laptop - 1)) {
    display: block;
  }
}
.breakpoint-until-desktop {
  @media screen and (max-width: ($desktop - 1)) {
    display: block;
  }
}
.breakpoint-until-desktopLarge {
  @media screen and (max-width: ($desktop-large - 1)) {
    display: block;
  }
}
</style>
