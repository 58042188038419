<script setup lang="ts">
import { type HeroBlockV2, normalizeHeroBlockV2 } from 'types/storyblok/hero-block-v2'
import { breakpointsConfig } from 'utils/css/breakpoints'
import { getEdgeClasses } from '~/lib/getEdgeClasses'
import type { trackBlockClickEmit } from '~/types/track-block-click'

/* Props & Emits */

const props = defineProps<{
  blok: HeroBlockV2
  multiHeroIndex?: number
}>()
const emit = defineEmits<trackBlockClickEmit>()

/* Refs & Composables */

const ctaElement = ref<HTMLElement>()
const isTablet = useMediaQuerySSR(`(min-width: ${breakpointsConfig.tablet}px)`)
const attrs = useAttrs()

/* Computed */

const isFirstIndex = computed(() => attrs['data-position'] === 0)
const hero = computed(() => normalizeHeroBlockV2(props.blok))
const showContentBlock = computed(() => hero.value.description.text && hero.value.heading.standard)

const containerEdgeStyle = computed(() => {
  if (!isFirstIndex.value && props.multiHeroIndex === undefined)
    return getEdgeClasses(hero.value.edgeStyle)

  if (props.multiHeroIndex === 0)
    return getEdgeClasses(hero.value.edgeStyle)

  if (props.multiHeroIndex === 1 && isTablet.value)
    return getEdgeClasses(hero.value.edgeStyle)

  return undefined
})
const contentEdgeStyle = computed(() => hero.value.edgeStyle)

const elementType = computed(() => hero.value.cta.url ? resolveComponent('NuxtLink') : 'div')

/* Functions */

function trackBlockClick(target: HTMLElement, url: string) {
  const clickType = ctaElement.value?.contains(target) ? 'button' : 'image'
  emit('trackBlockClick', url, clickType)
}
</script>

<template>
  <div v-editable="blok" data-test="hero-landing-page">
    <!-- Hero -->
    <component :is="elementType"
      class="hero"
      :target="hero.cta.url?.startsWith('http') ? '_blank' : '_self'"
      :to="$url(hero.cta.url || '')"
      :aria-label="hero.cta.label"
      data-test="hero-link"
      :data-parent-position="$attrs['data-position']"
      :class="[
        containerEdgeStyle,
        {
          [`hero--height-limit-${hero.heightLimit}`]: hero.heightLimit,
          'hero--show-content-block': showContentBlock,
          'hero--multi-block': multiHeroIndex !== undefined,
        },
      ]"
      @click="(e: any) => trackBlockClick(e.target, $url(hero.cta.url || ''))"
    >
      <!-- Media -->
      <Breakpoint until="tablet">
        <HeroBlockAsset v-if="hero.asset.default" :asset="hero.asset.default" :data-parent-position="$attrs['data-position']" />
      </Breakpoint>
      <Breakpoint from="tablet">
        <HeroBlockAsset v-if="hero.asset.desktopOverride" :asset="hero.asset.desktopOverride" :data-parent-position="$attrs['data-position']" />
        <HeroBlockAsset v-else-if="hero.asset.default" :asset="hero.asset.default" :data-parent-position="$attrs['data-position']" />
      </Breakpoint>

      <!-- Heading:Overlay -->
      <img
        v-if="hero.heading.overlay"
        class="hero__heading-overlay"
        :src="hero.heading.overlay.url"
        :alt="hero.heading.overlay.alt"
      >

      <!-- Heading:Standard -->
      <div class="hero__heading-standard">
        <h1
          v-if="hero.heading.standard"
          class="hero__heading-standard__title"
          :style="{ '--text-color': hero.heading.standard.headingColor }"
        >
          {{ hero.heading.standard.heading }}
        </h1>

        <div v-if="!showContentBlock" ref="ctaElement">
          <HeroBlockCTA :cta="hero.cta" class="hero__heading-standard__cta" />
        </div>
      </div>
    </component>

    <!-- Hero content -->
    <div
      v-if="showContentBlock"
      class="hero-content"
      :class="[contentEdgeStyle]"
      :style="{ backgroundColor: hero.heading.standard?.headingColor || '' }"
    >
      <div
        class="hero-content__description"
        :style="{ color: hero.description.color }"
      >
        {{ hero.description.text }}
      </div>
      <div ref="ctaElement">
        <HeroBlockCTA
          :cta="hero.cta"
          class="hero-content__cta"
          tag="nuxt-link"
          @click="e => trackBlockClick(e.target, $url(hero.cta.url || ''))"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'assets/scss/typography/headings';
@import 'assets/scss/typography/body';
@import 'assets/scss/rules/breakpoints';
@import 'assets/scss/rules/edges';

.hero {
  $root: &;
  display: block;
  position: relative;
  overflow: hidden;

  &--height-limit-original {
    max-height: 75vh;
    @media (min-width: $tablet) {
      max-height: 85vh;
    }
  }
  &--height-limit-full {
    max-height: 100vh;
  }

  &__heading-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    height: 8rem;

    @media (min-width: $tablet) {
      height: 14rem;
    }
  }

  &__heading-standard {
    position: absolute;
    bottom: 4rem;
    left: 1.6rem;
    right: 1.6rem;

    #{$root}--show-content-block & {
      bottom: 3rem;
    }

    @media (min-width: $tablet) {
      bottom: 4.4rem;
      left: 2.4rem;
      right: 2.4rem;

      #{$root}--show-content-block & {
        bottom: 3.4rem;
      }
    }

    &__title {
      @include h5;

      color: var(--text-color);
      font-size: clamp(3.2rem, 5vw + 1.6rem, 5.2rem);
      padding-inline-end: 2.4rem;
      max-width: 600px;

      @media (min-width: $tablet) {
        font-size: clamp(4rem, 3vw + 1.6rem, 7.2rem);
        line-height: 1.06;
        max-width: 50%;

        #{$root}--multi-block & {
          max-width: 87.5%;
        }
      }
    }

    &__cta {
      margin-top: 2.4rem;
    }
  }
  &:focus-visible {
    border: 2px solid var(--black);
    border-radius: 2px;
    outline: none;
  }
}

.hero-content {
  padding-block: 2.4rem 4rem;
  padding-inline: 1.6rem;
  position: relative;

  @media (min-width: $tablet) {
    padding-inline: 2.4rem;
  }

  &__description {
    @media (min-width: $tablet) {
      max-width: 50%;
    }
  }

  &__cta {
    margin-top: 1.6rem;

    @media (min-width: $tablet) {
      margin-top: 2.4rem;
    }
  }
}

.teeth {
  @include teeth;
  margin-top: -1.2rem;
}
.scallop {
  @include scallop;
  margin-top: -1.2rem;
}
.wave {
  @include wave;
  margin-top: -1.2rem;
}
</style>
